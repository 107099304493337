@import './../App.scss';

.connexion {
    // border: 1px solid blue;

    section {
        width: 320px;
        // border: 1px solid blue;
        margin: auto;
        margin-top: 100px;
        box-shadow: 0px 8px 10px 0px rgba($color: $gris, $alpha: 0.25);
        padding: 15px;
        border-radius: 12px;
        text-align: center;

        img {
            width: 125px;
            margin-top: 15px;
        }
        h2 {
            // border: 1px solid red;
            font-weight: normal;
            color: $gris;
            text-align: center;
            margin: 0;
            font-size: 1.3em;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        input,
        button {
            display: block;
            height: 50px;
            margin: auto;
            margin-bottom: 15px;
            width: 85%;
            box-sizing: border-box;
            padding-left: 10px;
            transition: opacity 200ms ease-in-out;
            border: 1px solid $grisLight;
            border-radius: 6px;
            font-family: "interM";
            color: $gris;
        }

        button {
            cursor: pointer;
            color: white;
            border: none;
            font-family: "interR";
            font-size: 0.75em;
        }
    }
}