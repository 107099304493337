$vert: #54BA78;
$blanc_casse: #F3FDF7;
$saumon: #FE8873;
$gris: #555555;
$grisLight: #AFAFAF;
$grisXLight: #EAEAEA;
$grisClair: #797979;

@keyframes load {
    100% {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: 'interL';
    src: url('./fonts/Inter-Light.ttf')
}


@font-face {
    font-family: 'interR';
    src: url('./fonts/Inter-Regular.ttf')
}

@font-face {
    font-family: 'interM';
    src: url('./fonts/Inter-Medium.ttf')
}

@font-face {
    font-family: 'interB';
    src: url('./fonts/Inter-Bold.ttf')
}

html{
    font-family: "interR";
}


#root{
    overflow-x: hidden;
}


.cross{
    position: absolute;
    top: -15px;
    right: -15px;
    background-color: $grisLight;
    color: white;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    cursor: pointer;

    img{
        width: 60%;
        position: absolute;
        top: 20%;
        left: 20%;
        transform: rotate(45deg);
    }
}

section{
    // border: 3px solid purple;
    width: 100%;
    max-width: 350px;
    margin: auto;

    .deconnexion{
        border: none;
        cursor: pointer;
        display: block;
        margin: auto;
        margin-top: 25px;
        width: 164px;
        height: 38px;
        border-radius: 6px;
        padding: 12px 9px 12px 9px;
        background-color: $saumon;
        color: $blanc_casse;
        line-height: 0;
    }
}

button {
    border: none;
    cursor: pointer;
    display: block;
    width: 80px;
    font-size: 0.65em;
    font-family: "interL";
    height: 30px;
    border-radius: 6px;
    padding: 12px 9px 12px 9px;
    color: $blanc_casse;
    line-height: 0;
    background-color: $vert;

    &.creux {
        background-color: white;
        border: 1px solid $vert;
        color: $vert;
        transition: background-color 250ms ease-in-out, color 180ms 55ms ease-in-out;
        

        &:hover{
            background-color: $vert;
            color: $blanc_casse;
            }

        &.saumon {
            border-color: $saumon;
            color: $saumon;
            background-color: white;
        }
    }

    &.saumon {
        background-color: $saumon;
    }
}


.loader {
    text-align: center;
    font-size: 1.75em;
    color: $gris;

    .loading {
        width: 25px;
        display: inline;
        margin-left: 10px;
        vertical-align: middle;
        height: 25px;
        animation: load 2s ease-in-out infinite;
    }
}
