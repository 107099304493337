@import './../App.scss';

.lecons {

    // border: 1px solid red;

    h1 {
        font-weight: normal;
        text-align: center;
        margin-top: 35px;
        margin-bottom: 45px;
        font-size: 1.2em;
    }

    .liste {
        color: $gris;

        .ligne {
            padding-bottom: 5px;
            margin-bottom: 15px;
            border-bottom: 1px solid $grisXLight;

            p {
                margin: 0;

                &.absent {
                    strong {
                        background-color: $saumon;
                    }
                }

                strong {
                    width: 40px;
                    background-color: $vert;
                    color: $blanc_casse;
                    // width: 35px;
                    text-align: center;
                    font-weight: normal;
                    display: inline-block;
                    font-size: 0.9em;
                    padding: 5px;
                    text-align: left;
                    margin-left: 2px;
                    margin-right: 2px;
                }
            }

            .heures {
                margin: 0;
                font-size: 0.9em;
                margin-top: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                strong {
                    color: $vert;

                    &.absent {
                        color: $saumon;
                    }
                }
            }
            .exam{
                font-family: "interR";
                font-weight: normal;
                font-size: 0.95em;
                margin-top: 5px;
                strong{
                    color: $vert;
                }
            }

        }
    }
}